import { Api } from './../utils/api';
import { autoinject, bindable, bindingMode, containerless, observable } from 'aurelia-framework';
import AuthService from 'authService';

@autoinject
export class EySeleccionAcc {

  @bindable todos: boolean = true;
  @bindable disabled: boolean = false;
  @bindable inline: boolean = false;
  @bindable filter: boolean = false;
  @observable tipoAccList: any = [];

  @bindable({ defaultBindingMode: bindingMode.twoWay }) value: string = "";
  constructor(private auth: AuthService, private api: Api) {
  }

  attached() {
    this.tipoAccList = [];
    if (this.todos) {
      this.tipoAccList.push({ "key": "", "description": this.api.tr("todos") });
    }

    if (!this.filter || this.auth.authData.prl_crea_anexo) {
      this.tipoAccList.push({ "key": "0", "description": this.api.tr("accidente_recaida") });
    }

    if (!this.filter || this.auth.authData.prl_crea_gestion) {
      this.tipoAccList.push({ "key": "1", "description": this.api.tr("incidente") });
    }

    if (!this.filter || this.auth.authData.prl_crea_informe) {
      this.tipoAccList.push({ "key": "2", "description": this.api.tr("patologia") });
    }

    if (!this.filter || this.auth.authData.prl_crea_inspeccion) {
      this.tipoAccList.push({ "key": "3", "description": this.api.tr("enfermedad_profesional") });
    }

    if (!this.filter || this.auth.authData.prl_crea_libro) {
      this.tipoAccList.push({ "key": "4", "description": this.api.tr("enfermedad_comun") });
    }

  }
}
