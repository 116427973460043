import { autoinject } from 'aurelia-framework';
import * as $ from 'jquery';

@autoinject
export class EyTooltipCustomAttribute {
  constructor(private element: Element) {
    this.element.setAttribute("data-toggle", "tooltip");
    // this.element.setAttribute("data-placement", "top");
  }

  attached() {
    $(this.element).tooltip({ trigger: "hover" }).click(function () {
      $('[data-toggle="tooltip"]').tooltip("hide");
    });;
  }

  detached() {
    $(this.element).tooltip('dispose');
  }
}


