import { Empresa } from 'entities/empresa';
import { AsignacionCdt } from 'entities/centro';
import { autoinject, containerless, observable } from 'aurelia-framework';
import { Trabajador } from 'entities/trabajador';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Api } from 'utils/api';
import { DialogController } from 'aurelia-dialog';
import moment from 'moment';
import { EmpresaCdT } from 'entities/centro';

@containerless()
@autoinject
export class EyTrabajadoresAsignacion {

  @observable empresaCdt: EmpresaCdT;
  @observable trabList: any[] = [];
  @observable fecha: string;
  @observable hora: string;

  title: string;
  errors: string;
  selectedTrabs: any[] = [];


  constructor(private api: Api, private ea: EventAggregator, private controller: DialogController) {
    let dt = moment();
    this.fecha = dt.format('DD-MM-YYYY');
    this.hora = dt.format('HH:mm:ss');
  }

  activate(model) {
    this.title = this.api.tr("asignar_trab_cdt");
    this.empresaCdt = model.empresaCdt;

    let emp = this.empresaCdt.empresaCached;

    console.log("Comprobando", emp, emp.trabajadores);

    if (emp && emp.trabajadores && emp.trabajadores.length > 0) {
      this.actualizarTrabajadores(emp);
    } else {

      this.api.getTrabajadores(emp)
        .then(() => this.actualizarTrabajadores(emp));
    }
  }

  actualizarTrabajadores(emp: Empresa) {
    console.log("actualizarTrabajadores", this.empresaCdt.trabajadoresCdt)
    let availableTrabs: Trabajador[] = [];
    if (emp && emp.trabajadores) {
      // Carga los trabajadores de la empresa que NO estén presentes en este centro
      emp.trabajadores.forEach(t => {
        if (t.activo == 1) {
          let presente = this.empresaCdt.trabajadoresCdt.find((tCdt) => { return (tCdt.trabajador.id == t.id) && tCdt.presente != undefined && tCdt.presente != 0; });
          console.log("actualizarTrabajadores", t.nombre, t.activo)
          if (!presente) {
            availableTrabs.push(t);
          }
        }
      });
    }

    availableTrabs.sort((a, b) => {
      if (a.nombreCompleto < b.nombreCompleto) { return -1; }
      if (a.nombreCompleto > b.nombreCompleto) { return 1; }
    });

    this.trabList = [];
    availableTrabs.forEach(t => this.trabList.push({ key: t.id, description: t.nombreCompleto + " (" + t.nif + ")" }));


    console.log("trabajadores", this.trabList);
  }

  attached() {

  }

  save() {

    this.errors = "";

    console.log(this.fecha, this.hora);
    let dt = moment(this.fecha + " " + this.hora, 'DD/MM/YYYY HH:mm:ss');
    if (!dt.isValid()) {
      this.errors = this.api.tr("fecha_entrada_error");
      return;
    }
    console.log("fechaEntrada (2):", dt);


    let as = new AsignacionCdt();
    as.trabajadores = this.selectedTrabs;
    as.fecha_entrada = dt.format('DD-MM-YYYY');
    as.hora_entrada = dt.format('HH:mm:ss');


    this.api.saveOne(AsignacionCdt, as, 'empresa_cdt_asigna_trabajadores', this.empresaCdt.id)
      .then(response => {
        console.log("Response: ", response)

        if (response.error) {
          return;
        }
        // if (response.error) {
        // this.errors = response.error;
        // return;
        // }

        this.controller.close(true, response);

        // console.log("newTrab", response);

        // let newCode = response.codigo;

        // this.ea.publish("trabajador::edit", { empID: this.trabajador.empresa, trabID: newCode });

      })
      .catch(error => {
        this.errors = this.errors = this.api.tr("error_actualizacion") + ": " + error;
      });
  }

  cancel() {
    this.controller.cancel(false);
  }
}
