import { autoinject } from 'aurelia-framework';


@autoinject
export class EySinInformacion {

  constructor() {
  }
}


