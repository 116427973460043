import { autoinject, bindable, bindingMode, containerless, observable, computedFrom } from 'aurelia-framework';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { Api } from 'utils/api';
import { Maquina } from 'entities/maquinas';
import { Router } from 'aurelia-router';
import { Empresa } from 'entities/empresa';
import AuthService from 'authService';
import { EyTrabajadorForm } from 'components/ey-trabajador-form';

@containerless()
@autoinject
export class EyMaquinas {

  @bindable empresa: Empresa;
  @bindable maquinas: Maquina[] = [];
  // @bindable validadoSeleccion: string = "1";  
  @observable terminado: boolean = false;

  tab: string = "alta";
  table: any;
  docSub: Subscription;

  constructor(private api: Api, private ea: EventAggregator, private auth: AuthService) {
  }

  attached() {
    console.log("EyMaquinas attached");
    this.docSub = this.ea.subscribe("select::doc", (data) => {
      console.log("select::doc", data);

      if (data.owner instanceof Maquina) {
        this.editMaquina(data.owner, data.filterType);
      }
    });
  }

  detached() {
    console.log("EyMaquinas detached");
    this.docSub.dispose();
  }

  // updateTable() {
  //   this.api.loadDataTable("maquinas")
  //     .then(table => {
  //       this.terminado = true;
  //       this.table = table;
  //     });
  // }

  editMaquina(maq: Maquina, filterType?: any) {
    this.ea.publish("maquina::edit", { empID: maq.empresa, maqID: maq.id, filterType: filterType });
  }

  selectTab(tab: string) {
    this.tab = tab;
  }


  // newTrab() {
  //   // this.api.nuevoTrabajador(this.empresa);

  //   let trab = new Trabajador();
  //   trab.empresa = this.empresa.id;
  //   trab.empresaCached = this.empresa;

  //   this.api.showEditorDialog({ viewModel: EyTrabajadorForm, model: { trabajador: trab } })
  //     .then((response) => {
  //       if (response && !response.wasCancelled) {
  //         console.log("newTrab", response)
  //       }
  //     });
  // }
}
