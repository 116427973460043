import { autoinject } from 'aurelia-framework';

@autoinject
export class EyTableButtonCustomAttribute {
  constructor(private element: Element) {
    this.element.classList.add("btn");
    this.element.classList.add("btn-sm");
    this.element.classList.add("btn-outline-primary");
    this.element.classList.add("m-1");
  }
}


