import { EventAggregator } from 'aurelia-event-aggregator';
import { Api } from './../utils/api';
import { autoinject, bindable, bindingMode, containerless, observable } from 'aurelia-framework';
import AuthService from 'authService';
import { Empresa, EmpresaCCC } from 'entities/empresa';
import moment from 'moment';

@autoinject
export class EySeleccionEmpCcc {

  @bindable empresa: Empresa;


  @observable tipoDeltaContaList: any[] = [];
  @observable tipoDeltaConta: string;

  @observable empCCCList: EmpresaCCC[] = [];
  @observable value: EmpresaCCC;

  @observable mes: number;
  @observable mesList: any[] = [];

  @observable year: number;
  @observable yearList: any[] = [];

  @observable tipoList: any[] = [];
  @observable tipoBaja: any;

  // @observable fecha: moment.Moment;

  constructor(private auth: AuthService, private api: Api, private ea: EventAggregator) {
  }

  attached() {
    let fecha = moment();
    this.mes = fecha.month() + 1;
    this.year = fecha.year();

    console.log("fecha", fecha, "mes", this.mes, "year", this.year);

    this.tipoDeltaContaList = [];
    this.tipoDeltaContaList.push({ "key": "", "description": "TODOS" });
    this.tipoDeltaContaList.push({ "key": "DELTA", "description": "DELTA" });
    this.tipoDeltaContaList.push({ "key": "CONTA", "description": "CONTA" });


    this.mesList = [];
    for (let m = 1; m <= 12; m++) {
      this.mesList.push({ "key": m, "description": String(m).padStart(2, '0') });
    }

    this.yearList = [];
    for (let y = this.year; y > this.year - 10; y--) {
      this.yearList.push({ "key": y, "description": String(y).padStart(4, '0') });
    }

    this.tipoList = [];
    this.tipoList.push({ "key": 0, "description": this.api.tr("sin_baja") });
    this.tipoList.push({ "key": 1, "description": this.api.tr("con_baja") });
  }

  empresaChanged() {
    this.api.getEmpresasCCC(this.empresa.id).then((list) => {
      let ninguno = new EmpresaCCC();
      ninguno['CCC'] = "";
      this.empCCCList = [ninguno];
      this.empCCCList.push(...list);
    });
  }

  buscar() {

    let fecha = "01-" + String(this.mes).padStart(2, '0') + "-" + this.year;

    console.log("buscar", fecha);
    this.ea.publish('filter_delta', { tipoDeltaConta: this.tipoDeltaConta, fecha: fecha, ccc: this.value.ccc, tipoBaja: this.tipoBaja });
  }
}
