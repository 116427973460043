import { MedidaPreventiva, TrabajadorAccidente } from './../entities/actPrev';
import { EyActPrevMcForm } from './ey-act-prev-mc-form';
import { EyActPrevVisitaInfo } from './ey-act-prev-visita-info';
import { MedidaCorrectora, TipoActividadPreventiva } from 'entities/actPrev';
import { ActividadPreventiva } from 'entities/actPrev';
import { autoinject, bindable, bindingMode, containerless, observable, computedFrom } from 'aurelia-framework';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { Api } from 'utils/api';
import AuthService from 'authService';
import { EmpresaCdT } from 'entities/centro';
import moment from 'moment';
import { noConflict } from 'lodash';

@containerless()
@autoinject
export class EyMedCorr { //EyActPrevVisita2
  @bindable empresaCdt: EmpresaCdT;
  @bindable tipo: number = 0; // 0: Todos, 1: Solo actPrev, 2: Solo Acc
  @bindable actividadPreventiva: ActividadPreventiva;
  @bindable accTrab: TrabajadorAccidente;
  @observable medCorrectoras: MedidaCorrectora[] = [];
  @bindable completa: boolean = false;
  @bindable pendientes: boolean = false;

  @bindable showFechas: boolean = false;
  @observable fechaDesde: moment.Moment;
  @observable fechaHasta: moment.Moment;

  parent: any;

  @observable terminado: boolean = false;

  lastRequest: Promise<any>;
  fechaSub: Subscription;

  constructor(private element: Element, private api: Api, private ea: EventAggregator, private auth: AuthService) {
    this.fechaHasta = moment();
    this.fechaDesde = this.api.getStartTime(this.fechaHasta);
  }

  attached() {
    console.log("EyMedCorr attached", this.empresaCdt, this.actividadPreventiva);

    this.fechaSub = this.ea.subscribe('filter_fechas', (data) => {
      this.fechaDesde = data.f_desde;
      this.fechaHasta = data.f_hasta;

      this.updateData();
    });

    this.updateData();
  }

  detached() {
    console.log("EyMedCorr detached");
    this.fechaSub.dispose();
  }

  empresaCdtChanged() {
    console.log("EyMedCorr empresaCdtChanged", this.empresaCdt);
    this.updateData();
  }

  accTrabChanged() {
    console.log("EyMedCorr accTrabChanged", this.accTrab);
    this.updateData();
  }
  // empresaCdtChanged() {
  //   // if (!this.ready) {
  //   //   return;
  //   // }
  //   console.log("EyActPrevVisita empresaCdtChanged", this.empresaCdt)
  //   this.updateData();
  // }

  getParams(): any {
    // if (!this.empresaCdt) {
    //   return;
    // }

    let params = {
      empresa: this.empresaCdt.empresa_id,
      empresa_cdt: this.empresaCdt.id,
    }

    if (this.pendientes) {
      params['pendientes'] = true
    }

    params['tipo'] = this.tipo;

    if (this.actividadPreventiva) {
      params['actividad'] = this.actividadPreventiva.id
    }

    if (this.accTrab) {
      params['acc_trab'] = this.accTrab.id;
    }

    if (this.showFechas) {
      params['f_desde'] = this.api.getFechaFormatted(this.fechaDesde);
      params['f_hasta'] = this.api.getFechaFormatted(this.fechaHasta);
    }
    return params;
  }


  updateData() {


    console.log("EyMedCorr updateData", this.empresaCdt)

    if (!this.empresaCdt || this.tipo == -1) {
      return;
    }

    // if (this.tipo == 1 && !this.actividadPreventiva) {
    //   return;
    // }

    // if (this.tipo == 2 && !this.accTrab) {
    //   return;
    // }

    let params = this.getParams();

    console.log("EyMedCorr asking for data", params);


    let currentRequest: Promise<any>;
    this.lastRequest = currentRequest = this.api.getList(MedidaCorrectora, 'medidas_correctoras', params)
      .then((items) => {

        if (this.lastRequest != currentRequest) {
          console.log("Se esta procesando otra petición más reciente, yo pedí:", params);
          return;
        }

        this.terminado = true;
        this.medCorrectoras = items;
        // items.foreach(mc => {
        //   mc.actPrev = this.actividadPreventiva;
        // });

        for (let mc of this.medCorrectoras) {
          mc.update();
        }
        if (this.actividadPreventiva) {
          this.actividadPreventiva.situacionesRiesgo = this.medCorrectoras;
        }
        if (this.accTrab) {
          this.accTrab.med_corr = this.medCorrectoras;
        }
      });
  }

  setState(mc: MedidaCorrectora, state: string) {

    // let prevState = mc.inc_especifica_valor;
    mc.inc_especifica_valor = state;
    this.guardar(mc);
    // if ((mc.inc_especifica_valor == '1' || mc.inc_especifica_valor == '2') && !mc.requiereFechaImp) {
    //   // guardar directo
    //   this.guardar(mc);
    // } else {
    //   this.edit(mc, prevState);
    // }
  }


  setStateMp(mp: MedidaPreventiva, state: string) {

    let prevState = mp.mp_especifica_valor;
    mp.mp_especifica_valor = state;
    if ((mp.mp_especifica_valor == '1' || mp.mp_especifica_valor == '2') && !mp.requiereFechaImp) {
      // guardar directo
      this.guardarMp(mp);
    } else {
      this.editMp(mp, prevState);
    }
  }



  // edit(mc: MedidaCorrectora, prevState: string = "-1") {

  //   this.api.showEditorDialog({ viewModel: EyActPrevMcForm, model: { parent: this, mc: mc } })
  //     .then((response) => {
  //       if (response.wasCancelled) {
  //         // Volvemos a dejarlo como estaba
  //         if (prevState != "-1") {
  //           mc.inc_especifica_valor = prevState;
  //         }
  //       }
  //     });
  // }

  editMp(mp: MedidaPreventiva, prevState: string = "-1") {

    this.api.showEditorDialog({ viewModel: EyActPrevMcForm, model: { parent: this, mp: mp } })
      .then((response) => {
        if (response.wasCancelled) {
          // // Volvemos a dejarlo como estaba
          // if (prevState != "-1") {
          //   mp.mp_especifica_valor = prevState;
          // }
          this.updateData();
        }
      });
  }


  guardar(mc: MedidaCorrectora): Promise<any> {

    return this.api.saveOne(MedidaCorrectora, mc, 'medidas_correctoras', mc.id)
      .then(response => {
        console.log("Response: ", response)

        if (response.error) {
          return;
        }

        this.actualizarMC(mc);
      })
      .catch(error => {
        console.log("Error: ", error);
      });
  }

  actualizarMC(mc: MedidaCorrectora) {

    this.updateData();
    
    // let params = this.getParams();
    // this.api.getOne(MedidaCorrectora, 'medidas_correctoras', mc.id, params)
    //   .then((updatedMc) => {
    //     // mc.fecha_inicio = updatedMc.fecha_inicio;
    //     // mc.fecha_implementacion = updatedMc.fecha_implementacion;
    //     // mc.fecha_prevista = updatedMc.fecha_prevista;

    //     updatedMc.update();
    //     // for(let i=0; i<this.medCorrectoras.length; i++){
    //     //   if (this.medCorrectoras[i].id == mc.id){
    //     //     this.medCorrectoras[i] = updatedMc;
    //     //   }
    //     // }
    //     mc.inc_especifica_valor = updatedMc.inc_especifica_valor;
    //     mc.med_prev = updatedMc.med_prev;
    //   });
  }

  guardarMp(mp: MedidaPreventiva): Promise<any> {

    return this.api.saveOne(MedidaPreventiva, mp, 'medidas_preventivas', mp.id)
      .then(response => {
        console.log("Response: ", response)

        if (response.error) {
          return;
        }


        // TODO: actualizar Medida Correctora dependiendo del valor de MedidaPreventiva

        // if (!mp.medCorrectora.med_prev || mp.medCorrectora.med_prev.length == 0) {
        //   // NO PUEDE PASAR
        // } else if (mp.medCorrectora.med_prev.length == 1) {
        //   // sincronizar
        //   mp.medCorrectora.inc_especifica_valor = mp.mp_especifica_valor;
        //   mp.medCorrectora.inc_especifica_por = mp.mp_especifica_por;
        // } else {
        //   // Caso a caso
        // }

        // this.guardar(mp.medCorrectora);

        this.actualizarMC(mp.medCorrectora);

        // this.api.getOne(MedidaPreventiva, 'medidas_preventivas', mp.id)
        //   .then((updatedMp) => {
        //     mp.fecha_inicio = updatedMp.fecha_inicio;
        //     mp.fecha_implementacion = updatedMp.fecha_implementacion;
        //     mp.fecha_prevista = updatedMp.fecha_prevista;
        //     // mp.med_prev = updatedMp.med_prev;
        //   });
      })
      .catch(error => {
        console.log("Error: ", error);
      });
  }

  showExtraInfo(mc: MedidaCorrectora) {
    this.api.showEditorDialog({ viewModel: EyActPrevVisitaInfo, model: { mc: mc } });
  }


}
