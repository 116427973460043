import { autoinject } from 'aurelia-framework';

@autoinject
export class EyTableHeaderCustomAttribute {
  constructor(private element: Element) {
    this.element.classList.add("table-secondary");
  }
}


