import { TrabajadorCdt } from 'entities/trabajadorCdt';
import { AsignacionCdt, DesasignacionCdt } from 'entities/centro';
import { autoinject, containerless, observable } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Api } from 'utils/api';
import { DialogController } from 'aurelia-dialog';
import moment from 'moment';
import { EmpresaCdT } from 'entities/centro';

@containerless()
@autoinject
export class EyTrabajadoresDesasignacion {

  @observable empresaCdt: EmpresaCdT;
  @observable trabList: any[] = [];
  @observable fecha: string;
  @observable hora: string;

  title: string;
  errors: string;
  selectedTrabs: any[] = [];

  trabUnico: TrabajadorCdt = undefined;

  constructor(private api: Api, private ea: EventAggregator, private controller: DialogController) {
    let dt = moment();
    this.fecha = dt.format('DD-MM-YYYY');
    this.hora = dt.format('HH:mm:ss');
  }

  activate(model) {
    this.title = this.api.tr("desasignar_trab_ct");
    this.empresaCdt = model.empresaCdt;
    this.trabUnico = model.trab;

    this.actualizarTrabajadores();
    // let emp = this.empresaCdt.empresaCached;

    // if (emp && emp.trabajadores && emp.trabajadores.length > 0) {
    //   this.actualizarTrabajadores();
    // } else {

    //   this.api.getTrabajadores(emp)
    //     .then(() => this.actualizarTrabajadores());
    // }
  }

  actualizarTrabajadores() {

    let assignedTrabs: TrabajadorCdt[] = [];
    if (!this.trabUnico) {
      if (this.empresaCdt.trabajadoresCdt) {
        // Carga los trabajadores de la empresa que NO estén presentes en este centro
        this.empresaCdt.trabajadoresCdt.forEach(t => {
          if (t.presente == 1) {
            assignedTrabs.push(t);
          }
        });
      }

      assignedTrabs.sort((a, b) => {
        if (a.nombreCompleto < b.nombreCompleto) { return -1; }
        if (a.nombreCompleto > b.nombreCompleto) { return 1; }
      });
    } else {
      // Desasignando uno a uno
      assignedTrabs.push(this.trabUnico);
      this.selectedTrabs.push(this.trabUnico.id);
    }

    this.trabList = [];
    assignedTrabs.forEach(t => this.trabList.push({ key: t.id, description: t.nombreCompleto + " (" + t.trabajador.nif + ")" }));
    console.log("trabajadores", this.trabList);
  }

  attached() {
  }

  save() {

    this.errors = "";

    let dt = moment(this.fecha + " " + this.hora, 'DD/MM/YYYY HH:mm:ss');
    if (!dt.isValid()) {
      this.errors = this.api.tr("fecha_salida_error");
      return;
    }
    console.log("fechaSalida (2):", dt);


    let des = new DesasignacionCdt();
    des.trabajadores = this.selectedTrabs;
    des.fecha_salida = dt.format('DD-MM-YYYY');
    des.hora_salida = dt.format('HH:mm:ss');


    this.api.saveOne(DesasignacionCdt, des, 'empresa_cdt_desasigna_trabajadores', this.empresaCdt.id)
      .then(response => {
        console.log("Response: ", response)

        if (response.error) {
          return;
        }
        // if (response.error) {
        // this.errors = response.error;
        // return;
        // }

        this.controller.close(true, response);

        // console.log("newTrab", response);

        // let newCode = response.codigo;

        // this.ea.publish("trabajador::edit", { empID: this.trabajador.empresa, trabID: newCode });

      })
      .catch(error => {
        this.errors = this.api.tr("error_actualizacion") + ": " + error;
      });
  }

  cancel() {
    this.controller.cancel(false);
  }
}
