import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, bindable } from 'aurelia-framework';
import moment from 'moment';
import { Api } from 'utils/api';

@autoinject
export class EySeleccionFechas {


  @bindable desde: moment.Moment;
  @bindable hasta: moment.Moment;
  @bindable soloDesde: boolean = false;
  @bindable fieldDesde: string = "desde";
  @bindable fieldHasta: string = "hasta";
  @bindable key: string = "";


  constructor(private api: Api, private ea: EventAggregator) {
  }

  attached() {

    if (this.key != "") {
      this.fieldDesde = "desde_" + this.key;
      this.fieldHasta = "hasta_" + this.key;
    }

    //console.log("attached", this.fieldDesde, this.fieldHasta)
  }

  buscar() {

    let fechaDesde = moment(this.desde, "DD-MM-YYYY");

    let fechaHasta;
    if (!this.soloDesde) {

      fechaHasta = moment(this.hasta, "DD-MM-YYYY");

      if (fechaDesde > fechaHasta) {
        this.api.alert(this.api.tr("error_detectado"), this.api.tr("fecha_no_valida"));
        return;
      }
    }

    // console.log("Filtrar fechas", { f_desde: fechaDesde, f_hasta: fechaHasta });
    this.ea.publish('filter_fechas', { f_desde: fechaDesde, f_hasta: fechaHasta });
  }
}
