import { autoinject, containerless, observable } from 'aurelia-framework';
import { Trabajador } from 'entities/trabajador';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Api } from 'utils/api';
import { DialogController } from 'aurelia-dialog';
import moment from 'moment';

@containerless()
@autoinject
export class EyTrabajadorForm {

  @observable trabajador: Trabajador;
  title: string;
  @observable errors: string;
  ipfList: any[] = [];
  sexoList: any[] = [];

  constructor(private api: Api, private ea: EventAggregator, private controller: DialogController) {

    console.log("EyTrabajadorForm");

    this.ipfList.push({ key: 1, description: "Número de Identificación Fiscal (NIF)" });
    this.ipfList.push({ key: 2, description: "Pasaporte" });
    this.ipfList.push({ key: 6, description: "Número de Identificación de Extranjero (NIE)" });

    this.sexoList.push({ key: 0, description: "Hombre" });
    this.sexoList.push({ key: 1, description: "Mujer" });
  }

  activate(model) {
    this.title = "Editar trabajador";
    this.trabajador = model.trabajador;
  }

  attached() {
  }

  save() {

    this.errors = "";

    if (!this.validateFechaNac()) {
      return;
    }

    let fAlta = moment(this.trabajador.fase_alta, "DD-MM-YYYY");
    if (!fAlta.isValid()) {
      this.errors = "Fecha alta no válida";
      return;
    }

    this.api.saveOne(Trabajador, this.trabajador, 'trabajadores', this.trabajador.id)
      .then(response => {
        console.log("Response: ", response)

        if (response.error) {
          return;
        }
        // if (response.error) {
        // this.errors = response.error;
        // return;
        // }

        this.controller.close(true, response);

        console.log("newTrab", response);

        let newCode = response.codigo;

        this.ea.publish("trabajador::edit", { empID: this.trabajador.empresa, trabID: newCode });

      })
      .catch(error => {
        this.errors = this.api.tr("error_actualizacion") + ": " + error;
      });
  }

  cancel() {
    this.controller.cancel(false);
  }

  validateFechaNac(): boolean {

    let dt = moment(this.trabajador.fecha_nac, "DD-MM-YYYY");
    if (!dt.isValid()) {
      this.errors = "Fecha nacimiento no válida";
      return false;
    }

    let age = moment().diff(dt, 'years')
    if (age < 18) {
      this.errors = "Menor de 18 años";
      return false;
    } else if (age > 65) {
      this.errors = "Mayor de 65 años";
      return false;
    }

    console.log("fechaNac:", dt, this.errors, age);
    return true;
  }
}
