import { autoinject, bindable, bindingMode, containerless, observable, computedFrom } from 'aurelia-framework';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { Api } from 'utils/api';
import { Trabajador } from 'entities/trabajador';
import { Router } from 'aurelia-router';
import { Empresa } from 'entities/empresa';
import AuthService from 'authService';
import { EyTrabajadorForm } from 'components/ey-trabajador-form';

@containerless()
@autoinject
export class EyTrabajadores {

  @bindable empresa: Empresa;
  @bindable trabajadores: Trabajador[] = [];
  @bindable validadoSeleccion: string = "1";

  @observable terminado: boolean = false;

  tab: string = "alta";
  table: any;
  docSub: Subscription;

  constructor(private api: Api, private ea: EventAggregator, private auth: AuthService) {
  }

  attached() {
    console.log("EyTrabajadores attached");
    this.docSub = this.ea.subscribe("select::doc", (data) => {
      console.log("select::doc", data);

      if (data.owner instanceof Trabajador) {
        this.editTrabajador(data.owner, data.filterType);
      }
    });
  }

  detached() {
    console.log("EyTrabajadores detached");
    this.docSub.dispose();
  }

  // updateTable() {
  //   this.api.loadDataTable("trabajadores")
  //     .then(table => {
  //       this.terminado = true;
  //       this.table = table;
  //     });
  // }

  editTrabajador(trab: Trabajador, filterType?: any) {
    this.ea.publish("trabajador::edit", { empID: trab.empresa, trabID: trab.id, filterType: filterType });
  }

  selectTab(tab: string) {
    this.tab = tab;
  }


  newTrab() {
    // this.api.nuevoTrabajador(this.empresa);

    let trab = new Trabajador();
    trab.empresa = this.empresa.id;
    trab.empresaCached = this.empresa;

    this.api.showEditorDialog({ viewModel: EyTrabajadorForm, model: { trabajador: trab } })
      .then((response) => {
        if (response && !response.wasCancelled) {
          console.log("newTrab", response)
        }
      });
  }
}
