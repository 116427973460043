import { autoinject, bindable, bindingMode, containerless } from 'aurelia-framework';

// @containerless()
@autoinject
export class EySeleccionValidado {
  @bindable({ defaultBindingMode: bindingMode.twoWay }) value: string = "0";
  constructor() {

  }

  valueChanged() {
    console.log("EySeleccionValidado Value", this.value);
  }
}
