import { Api } from './../utils/api';
import { autoinject, bindable, bindingMode, observable } from 'aurelia-framework';
import AuthService from 'authService';

// @containerless()
@autoinject
export class EySeleccionMedCorrTipo {

  @observable tipoList: any = [];

  @bindable({ defaultBindingMode: bindingMode.twoWay }) value: number = 0;
  constructor(private auth: AuthService, private api: Api) {
  }

  attached() {
    this.tipoList = [];
    this.tipoList.push({ "key": 0, "description": this.api.tr("todos") });
    this.tipoList.push({ "key": 1, "description": this.api.tr("act_prevs") });
    this.tipoList.push({ "key": 2, "description": this.api.tr("accidentes") });

  }
}
