import { autoinject, bindable, bindingMode, containerless, observable, computedFrom } from 'aurelia-framework';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { Api } from 'utils/api';
import { TrabajadorCdt } from 'entities/trabajadorCdt';
import AuthService from 'authService';
import { Empresa } from 'entities/empresa';
import { EmpresaCdT } from 'entities/centro';
import { Trabajador } from 'entities/trabajador';
import { EyTrabajadorForm } from './ey-trabajador-form';
import { EyTrabajadoresAsignacion } from './ey-trabajadores-asignacion';
import { EyTrabajadoresDesasignacion } from './ey-trabajadores-desasignacion';

@containerless()
@autoinject
export class EyTrabajadoresCdt {

  @bindable empresaCdt: EmpresaCdT;
  @bindable trabajadoresCdt: TrabajadorCdt[] = [];
  @bindable validadoSeleccion: string = "1";

  @observable terminado: boolean = false;

  tab: string = "alta";
  table: any;

  constructor(private api: Api, private ea: EventAggregator, private auth: AuthService) {
  }

  // updateTable() {
  //   this.api.loadDataTable("trabajadores")
  //     .then(table => {
  //       this.terminado = true;
  //       this.table = table;
  //     });
  // }

  editTrabajadorCdt(trab: TrabajadorCdt) {
    this.ea.publish("trabajador_cdt::edit",
      {
        empCdt: trab.empresa_cdt_id,
        trabCdtID: trab.id
      });
    // this.router.navigateToRoute('trabajadorEdit', { empID: this.empresa.id, trabID: trab.id });
  }

  selectTab(tab: string) {
    this.tab = tab;
  }

  newTrab() {
    let emp = this.empresaCdt.empresaCached;
    let trab = new Trabajador();
    trab.empresa = emp.id;
    trab.empresaCached = emp;

    this.api.showEditorDialog({ viewModel: EyTrabajadorForm, model: { trabajador: trab } })
      .then((response) => {
        if (response && !response.wasCancelled) {
          this.api.getTrabajadores(this.empresaCdt.empresaCached);
        }
      });
  }

  asignarTrab() {
    this.api.showEditorDialog({ viewModel: EyTrabajadoresAsignacion, model: { empresaCdt: this.empresaCdt } })
      .then((response) => {
        if (response && !response.wasCancelled) {
          this.api.getTrabajadoresCdt(this.empresaCdt);
        }
      });
  }

  desasignarTrab() {
    this.api.showEditorDialog({ viewModel: EyTrabajadoresDesasignacion, model: { empresaCdt: this.empresaCdt } })
      .then((response) => {
        if (response && !response.wasCancelled) {
          this.api.getTrabajadoresCdt(this.empresaCdt);
        }
      });
  }
}
