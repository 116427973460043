import { autoinject } from 'aurelia-framework';

@autoinject
export class EyTableCustomAttribute {
  constructor(private element: Element) {
    this.element.classList.add("table");
    this.element.classList.add("table-hover");
    // this.element.classList.add("table-striped");
    this.element.classList.add("table-sm");
    this.element.classList.add("table-condensed");
  }
}


