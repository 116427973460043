import { autoinject, bindable, bindingMode, containerless } from 'aurelia-framework';

@autoinject
export class EySeleccionTipoDoc {

  @bindable id: string = "";
  @bindable({ defaultBindingMode: bindingMode.twoWay }) tipo: number = 0;
  @bindable({ defaultBindingMode: bindingMode.twoWay }) obligatorio: number = 0;
  constructor() {

  }
}
