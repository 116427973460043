import { FrameworkConfiguration, PLATFORM } from 'aurelia-framework';

export function configure(config: FrameworkConfiguration): void {
  config.globalResources([
    PLATFORM.moduleName('./ey-form-input'),
    PLATFORM.moduleName('./ey-acc-form'),
    PLATFORM.moduleName('./ey-acc-trabs'),
    PLATFORM.moduleName('./ey-acc-trab-form'),
    PLATFORM.moduleName('./ey-acc-trab-investigacion'),
    PLATFORM.moduleName('./ey-act-prev-form'),
    PLATFORM.moduleName('./ey-act-prev-dup-form'),
    PLATFORM.moduleName('./ey-med-corr'),
    PLATFORM.moduleName('./ey-act-prev-mc-form'),
    PLATFORM.moduleName('./ey-act-prev-visita-info'),
    PLATFORM.moduleName('./ey-act-prev-trabs'),
    PLATFORM.moduleName('./ey-act-prev-trabs-form'),
    PLATFORM.moduleName('./ey-bool'),
    PLATFORM.moduleName('./ey-dialog'),
    PLATFORM.moduleName('./ey-table'),
    PLATFORM.moduleName('./ey-table-header'),
    PLATFORM.moduleName('./ey-table-button'),
    PLATFORM.moduleName('./ey-base-page'),
    PLATFORM.moduleName('./ey-cdt-gestion'),
    PLATFORM.moduleName('./ey-direccion'),
    PLATFORM.moduleName('./ey-direccion-completa'),
    PLATFORM.moduleName('./ey-documentos'),
    PLATFORM.moduleName('./ey-documento-resumen'),
    PLATFORM.moduleName('./ey-documento-upload'),
    PLATFORM.moduleName('./ey-documento-info'),
    PLATFORM.moduleName('./ey-documento-visor'),
    PLATFORM.moduleName('./ey-estado-doc'),
    PLATFORM.moduleName('./ey-estado-doc-valido'),
    PLATFORM.moduleName('./ey-seleccion-acc'),
    PLATFORM.moduleName('./ey-seleccion-act-prev'),
    PLATFORM.moduleName('./ey-seleccion-fechas'),
    PLATFORM.moduleName('./ey-seleccion-validado'),
    PLATFORM.moduleName('./ey-seleccion-tipo-doc'),
    PLATFORM.moduleName('./ey-sin-informacion'),
    PLATFORM.moduleName('./ey-trabajadores'),
    PLATFORM.moduleName('./ey-trabajadoresCdt'),
    PLATFORM.moduleName('./ey-trabajador-form'),
    PLATFORM.moduleName('./ey-trabajadores-asignacion'),
    PLATFORM.moduleName('./ey-trabajadores-desasignacion'),
    PLATFORM.moduleName('./ey-tooltip'),
    PLATFORM.moduleName('./ey-maquinas'),
    PLATFORM.moduleName('./ey-img'),
    PLATFORM.moduleName('./ey-dialog-fullscreen'),
    PLATFORM.moduleName('./ey-act-prev-plan-emergencia'),
    PLATFORM.moduleName('./ey-act-prev-reunion'),
    PLATFORM.moduleName('./ey-act-prev-informe'),
    PLATFORM.moduleName('./ey-act-prev-inspeccion'),
    PLATFORM.moduleName('./ey-act-prev-gest-doc'),
    PLATFORM.moduleName('./ey-act-prev-libro'),
    PLATFORM.moduleName('./informe-header'),
    PLATFORM.moduleName('./informe-seccion'),
    PLATFORM.moduleName('./ey-seleccion-med-corr-tipo'),
    PLATFORM.moduleName('./ey-delta'),
    PLATFORM.moduleName('./ey-seleccion-emp-ccc')
  ]);
}
