import { Api } from './../utils/api';
import { autoinject, bindable, bindingMode, containerless, observable } from 'aurelia-framework';
import AuthService from 'authService';

// @containerless()
@autoinject
export class EySeleccionActPrev {

  @bindable todos: boolean = true;
  @bindable disabled: boolean = false;
  @bindable inline: boolean = false;
  @bindable filter: boolean = false;
  @observable tipoActList: any = [];

  @bindable({ defaultBindingMode: bindingMode.twoWay }) value: string = "";
  constructor(private auth: AuthService, private api: Api) {
  }

  attached() {

    this.tipoActList = [];
    if (this.todos) {
      this.tipoActList.push({ "key": "", "description": this.api.tr("todos") });
    }


    if (!this.filter || this.auth.authData.prl_crea_anexo) {
      this.tipoActList.push({ "key": "P", "description": this.api.tr("anexo_pss") });
    }


    if (!this.filter || this.auth.authData.prl_crea_gestion) {
      this.tipoActList.push({ "key": "K", "description": this.api.tr("gest_doc") });
    }

    if (!this.filter || this.auth.authData.prl_crea_informe) {
      this.tipoActList.push({ "key": "H", "description": this.api.tr("informe") });
    }

    if (!this.filter || this.auth.authData.prl_crea_inspeccion) {
      this.tipoActList.push({ "key": "D", "description": this.api.tr("inspeccion") });
    }

    if (!this.filter || this.auth.authData.prl_crea_libro) {
      this.tipoActList.push({ "key": "N", "description": this.api.tr("lib_incid") });
    }

    if (!this.filter || this.auth.authData.prl_crea_pee) {
      this.tipoActList.push({ "key": "F", "description": this.api.tr("pee_des_per") });
    }

    if (!this.filter || this.auth.authData.prl_crea_reunion) {
      this.tipoActList.push({ "key": "C", "description": this.api.tr("reunion") });
    }

    if (!this.filter || this.auth.authData.prl_crea_visita) {
      this.tipoActList.push({ "key": "A", "description": this.api.tr("visita_cdt") });
    }

  }
  // valueChanged() {
  //   console.log("EySeleccionActPrev Value", this.value);
  // }
}
