import { bindable, containerless } from "aurelia-framework";


@containerless()
export class InformeHeader {
  @bindable title: string;


  constructor() {
  }
}
